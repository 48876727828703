<template>
    <div class="edit-manage">
        <el-dialog title="修改管理员信息"
                   :width="70+'%'"
                   :close-on-click-modal="false"
                   :before-close="cancleDiadLog"
                   :visible.sync="dialogFormVisible">
            <el-form
                    :model="manage"
                    v-loading="!manage">
                <el-form-item label="名称">
                    <el-input v-model="manage.name"  autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="用户名">
                    <el-input v-model="manage.username"  autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="邮箱">
                    <el-input v-model="manage.email"  autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="密码">
                    <el-input   placeholder="留空不修改密码" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="确认密码">
                    <el-input   placeholder="留空不修改密码" autocomplete="off"></el-input>
                </el-form-item>
                <el-button
                        :loading="loading"
                        type="primary" @click="updateManage">更新</el-button>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import { updateManager } from '@/api/manage';
    export default {
        props:['dialogFormVisible', 'manage', 'index'],
        name: "manageEdit",
        data(){
            return {
                loading: false
            }
        },
        methods:{
            cancleDiadLog(){
                this.$emit('closeDialog', this.index, this.manage)
            },
            async updateManage(){
                let manage = await updateManager('admin/manage/'+ this.manage.id, this.manage);
                if(manage){
                    if (manage.msg){
                        this.$message({
                            'message' : manage.msg,
                            'type' : 'success'
                        });
                        this.cancleDiadLog();
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>