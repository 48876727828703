import { get, post } from '@/utils/http'

export function getManagerList(t) {
    return get({
        url: 'admin/manage',
        method: 'get',
        params: t
    })
}

export function storeManager(url, param) {
    return post({
        url: url,
        method: 'post',
        params: param
    })
}


export function updateManager(url, param) {
    return post({
        url: url,
        method: 'PATCH',
        params: {'_method' : 'PATCH', ...param}
    })
}

export function deleteManager(url, param) {
    return post({
       url: url,
       method: 'delete',
       params:{'_method': 'DELETE', ...param}
    })
}