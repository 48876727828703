<template>
    <div class="manage">
        <div class="filter-container" :span="24">
        </div>
        <div class="grid-content">
            <el-button type="primary" icon="el-icon-plus" size="small" @click="handleCreate"> 添加</el-button>
        </div>
        <el-table
                v-loading="bxLoading"
                :data="manages"
                style="width: 100%">
            <el-table-column label="编号/ID" prop="id"></el-table-column>
            <el-table-column prop="email" label="邮箱"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="created_at" label="创建时间"></el-table-column>
            <el-table-column  label="操作">
                <template slot-scope="{$index, row}">
                    <el-button
                            size="mini"
                            @click="handleEdit($index, row)">编辑</el-button>
                    <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete($index, row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <manage-edit
                @closeDialog="handleCloseDialog"
                :manage="manage"
                :dialogFormVisible="dialogFormVisible" />
    </div>
</template>

<script>
    import { getManagerList, deleteManager } from '@/api/manage';
    import ManageEdit from './edit';
    import { EventBus } from "../../utils/event-bus";
    export default {
        name: "manageList",
        data(){
            return{
                bxLoading: true,
                manages: [],
                meta: {},
                dialogFormVisible: false,
                manage: {}
            }
        },
        created(){
          this.getManagerList();
          EventBus.$on('push-manage', manage => {
              this.manages.unshift(manage)
          })
        },
        methods:{
            async getManagerList(){
                let manages = await getManagerList();
                this.bxLoading = false;
                if(manages){
                    this.manages = manages.data;
                    this.meta = manages.meta;
                }
            },
            handleCreate(){
                this.$router.push({name:'manageCreate', query:{pagesize: this.meta.current_page, curr: this.current_page}})
            },
            // eslint-disable-next-line no-unused-vars
            handleEdit(index, row){
                this.dialogFormVisible = true;
                this.manage = row;
            },
            async handleDelete(index, row){
                try {

                    let confirm = await this.$confirm('此操作将永久删除该用户, 是否继续', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    if(confirm === 'confirm'){
                        deleteManager('admin/manage/'+ row.id + '?index=' + index,row).then((rsp) => {
                            if(rsp){
                                this.$delete(this.manages, index)
                            }
                        })
                    }
                }catch (e) {
                    console.log('cancle')
                }
            },
            handleCloseDialog(index, data){
                this.dialogFormVisible = false;
                this.$set(this.manages, index, data)
            }
        },
        components:{
            ManageEdit
        }
    }
</script>

<style scoped>
    .manage {
        background: #fff;
        padding: 15px
    }
</style>